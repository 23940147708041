var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from "react";
import { Typography, Stack, Divider, FormLabel, Grid, Button, Box, } from "@mui/joy";
import AddIcon from "@mui/icons-material/Add";
import Variable from "./components/Variable";
function Variables(props) {
    var variables = props.variables, pluginIndex = props.pluginIndex, invalidFields = props.invalidFields, onPluginVariablePropertyChange = props.onPluginVariablePropertyChange, onPluginNewVariable = props.onPluginNewVariable, onPluginDeleteVariable = props.onPluginDeleteVariable, onPluginVariableAddEnumValue = props.onPluginVariableAddEnumValue, onPluginVariableUpdateEnumValue = props.onPluginVariableUpdateEnumValue, onPluginVariableDeleteEnumValue = props.onPluginVariableDeleteEnumValue, 
    //VariableAction
    onVariableActionEnabled = props.onVariableActionEnabled, onVariableActionTypeChange = props.onVariableActionTypeChange, onVariableActionDescriptionChange = props.onVariableActionDescriptionChange, onVariableActionArgsPropertyChange = props.onVariableActionArgsPropertyChange, onVariableActionArgsNewHeader = props.onVariableActionArgsNewHeader, onVariableActionArgsUpdateHeader = props.onVariableActionArgsUpdateHeader, onVariableActionArgsDeleteHeader = props.onVariableActionArgsDeleteHeader, onVariableActionArgsDeleteAdditionalBodyVariable = props.onVariableActionArgsDeleteAdditionalBodyVariable, onVariableActionArgsNewAdditionalBodyVariable = props.onVariableActionArgsNewAdditionalBodyVariable, onVariableActionArgsUpdateAdditionalBodyVariable = props.onVariableActionArgsUpdateAdditionalBodyVariable;
    return (_jsxs(Stack, __assign({ spacing: 2 }, { children: [_jsx(Typography, __assign({ level: "title-sm" }, { children: "Variables" })), _jsxs(Typography, __assign({ level: "body-sm" }, { children: ["Variables are the inputs expected from the user. To handle nested objects, use the syntax", " ", _jsx(Typography, __assign({ variant: "outlined", color: "primary" }, { children: "address__street" })), "."] })), _jsx(Divider, {}), variables.length > 0 && (_jsxs(Box, __assign({ sx: { flexGrow: 1 } }, { children: [_jsxs(Grid, __assign({ container: true, spacing: 1, alignItems: "center", wrap: "nowrap" }, { children: [_jsx(Grid, { xs: 0.7, sx: { ml: 0, mr: 0.5, pl: 0.3, minWidth: "55px" } }), _jsx(Grid, __assign({ xs: 2 }, { children: _jsx(FormLabel, { children: "Name" }) })), _jsx(Grid, __assign({ xs: 2 }, { children: _jsx(FormLabel, { children: "Label" }) })), _jsx(Grid, __assign({ xs: 2 }, { children: _jsx(FormLabel, { children: "Type" }) })), _jsx(Grid, __assign({ xs: 4.3 }, { children: _jsx(FormLabel, { children: "Question (Optional)" }) })), _jsx(Grid, { xs: 0.5 }), _jsx(Grid, { xs: 0.5 })] })), variables.map(function (variable, varIndex) { return (_jsx(React.Fragment, { children: _jsx(Variable, { variable: variable, varIndex: varIndex, pluginIndex: pluginIndex, invalidFields: invalidFields, onPluginVariablePropertyChange: onPluginVariablePropertyChange, onPluginDeleteVariable: onPluginDeleteVariable, onPluginVariableAddEnumValue: onPluginVariableAddEnumValue, onPluginVariableUpdateEnumValue: onPluginVariableUpdateEnumValue, onPluginVariableDeleteEnumValue: onPluginVariableDeleteEnumValue, 
                            //VariableAction
                            onVariableActionEnabled: onVariableActionEnabled, onVariableActionTypeChange: onVariableActionTypeChange, onVariableActionDescriptionChange: onVariableActionDescriptionChange, onVariableActionArgsPropertyChange: onVariableActionArgsPropertyChange, onVariableActionArgsNewHeader: onVariableActionArgsNewHeader, onVariableActionArgsUpdateHeader: onVariableActionArgsUpdateHeader, onVariableActionArgsDeleteHeader: onVariableActionArgsDeleteHeader, onVariableActionArgsDeleteAdditionalBodyVariable: onVariableActionArgsDeleteAdditionalBodyVariable, onVariableActionArgsNewAdditionalBodyVariable: onVariableActionArgsNewAdditionalBodyVariable, onVariableActionArgsUpdateAdditionalBodyVariable: onVariableActionArgsUpdateAdditionalBodyVariable }) }, varIndex)); })] }))), _jsx(Stack, { children: _jsx(Button, __assign({ size: "sm", color: "primary", variant: "plain", startDecorator: _jsx(AddIcon, {}), onClick: function () { return onPluginNewVariable(pluginIndex); }, sx: { alignSelf: "flex-start" } }, { children: "Add new variable" })) })] })));
}
export default memo(Variables);
