var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useState } from "react";
import { Typography, Stack, Divider, FormControl, FormLabel, Grid, Select, Option, Textarea, } from "@mui/joy";
import VariableActionWebhookArgs from "./WebhookArgs";
function Action(props) {
    var action = props.action, pluginIndex = props.pluginIndex, variableIndex = props.variableIndex, onVariableActionEnabled = props.onVariableActionEnabled, onVariableActionTypeChange = props.onVariableActionTypeChange, onVariableActionDescriptionChange = props.onVariableActionDescriptionChange, onVariableActionArgsPropertyChange = props.onVariableActionArgsPropertyChange, onVariableActionArgsNewHeader = props.onVariableActionArgsNewHeader, onVariableActionArgsUpdateHeader = props.onVariableActionArgsUpdateHeader, onVariableActionArgsDeleteHeader = props.onVariableActionArgsDeleteHeader, onVariableActionArgsDeleteAdditionalBodyVariable = props.onVariableActionArgsDeleteAdditionalBodyVariable, onVariableActionArgsNewAdditionalBodyVariable = props.onVariableActionArgsNewAdditionalBodyVariable, onVariableActionArgsUpdateAdditionalBodyVariable = props.onVariableActionArgsUpdateAdditionalBodyVariable;
    var _a = useState(action.actionDescription), localActionDescriptionValue = _a[0], setLocalActionDescriptionValue = _a[1];
    var handleActionDescriptionChange = function (event) {
        setLocalActionDescriptionValue(event.target.value); // Update the local state on every keystroke
    };
    var handleActionDescriptionBlur = function () {
        // Sync local state with global state only when the input loses focus
        if (localActionDescriptionValue !== action.actionDescription) {
            onVariableActionDescriptionChange(pluginIndex, variableIndex, localActionDescriptionValue);
        }
    };
    return (_jsxs(Stack, __assign({ spacing: 2, sx: { mt: 2 } }, { children: [_jsx(Typography, __assign({ level: "body-sm" }, { children: "Specify the HTTP endpoint for action." })), _jsx(Divider, {}), _jsx(Grid, __assign({ container: true, columnSpacing: 2, rowSpacing: 1, alignItems: "center" }, { children: _jsx(Grid, __assign({ xs: 12 }, { children: _jsxs(FormControl, { children: [_jsx(FormLabel, { children: "Action Type" }), _jsx(Select, __assign({ size: "sm", value: action.type, onChange: function (event, newValue) {
                                    return onVariableActionTypeChange(pluginIndex, variableIndex, newValue);
                                } }, { children: _jsx(Option, __assign({ value: "webhook" }, { children: "HTTP Request" })) }))] }) })) })), action.type === "webhook" && action.argsObject && (_jsx(VariableActionWebhookArgs, { args: action.argsObject, pluginIndex: pluginIndex, variableIndex: variableIndex, onVariableActionEnabled: onVariableActionEnabled, onVariableActionArgsPropertyChange: onVariableActionArgsPropertyChange, onVariableActionArgsUpdateHeader: onVariableActionArgsUpdateHeader, onVariableActionArgsDeleteHeader: onVariableActionArgsDeleteHeader, onVariableActionArgsDeleteAdditionalBodyVariable: onVariableActionArgsDeleteAdditionalBodyVariable, onVariableActionArgsUpdateAdditionalBodyVariable: onVariableActionArgsUpdateAdditionalBodyVariable, onVariableActionArgsNewAdditionalBodyVariable: onVariableActionArgsNewAdditionalBodyVariable, onVariableActionArgsNewHeader: onVariableActionArgsNewHeader })), _jsx(FormLabel, { children: "Post Action Instructions (Optional)" }), _jsx(FormControl, __assign({ sx: { display: { sm: "flex-column", md: "flex-row" } } }, { children: _jsx(Textarea, { minRows: 4, size: "sm", value: localActionDescriptionValue, onChange: handleActionDescriptionChange, onBlur: handleActionDescriptionBlur, placeholder: "Provide clear instructions for the agent after completing this action. Example: If the response is 'unsubscribed', inform the user, end the call, or request an alternate contact number." }) }))] })));
}
export default memo(Action);
