var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import Sheet from "@mui/joy/Sheet";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ChatsPane from "./components/ChatsPane";
import MessagesPane from "./components/MessagesPane";
import { useChatHistory } from "../../data/hooks/useChatHistory";
import ChatListSkeleton from "../../components/ui/ChatListSkeleton";
import ChatSkeleton from "../../components/ui/ChatSkeleton";
export var ChatConversations = function () {
    var _a = useSearchParams(), searchParams = _a[0], setSearchParams = _a[1];
    var location = useLocation();
    var navigate = useNavigate();
    var sessionIdParam = searchParams.get("sessionId");
    var _b = useChatHistory(), sessions = _b.sessions, totalSessionCount = _b.totalSessionCount, conversationsIsLoading = _b.conversationsIsLoading, sessionsIsLoading = _b.sessionsIsLoading, getSessions = _b.getSessions, getSessionById = _b.getSessionById, conversations = _b.conversations, markSessionAsRead = _b.markSessionAsRead, getConversationsBySessionId = _b.getConversationsBySessionId;
    var _c = useState(null), selectedSessionId = _c[0], setSelectedSessionId = _c[1];
    var _d = useState([]), chatList = _d[0], setChatList = _d[1];
    var _e = useState(), selectedChat = _e[0], setSelectedChat = _e[1];
    var _f = useState(1), page = _f[0], setPage = _f[1];
    var loadMoreChats = function () {
        if (sessions && sessions.length < totalSessionCount) {
            setPage(function (prevPage) { return prevPage + 1; });
        }
    };
    var hasMoreChats = sessions && sessions.length < totalSessionCount;
    // Fetch sessions when page changes
    useEffect(function () {
        getSessions(page, 10, {});
    }, [page]);
    // Update chat list when sessions change
    useEffect(function () {
        if (sessions) {
            var chatsData = sessions.map(function (session) {
                var existingChat = chatList.find(function (chat) { return chat.id === session.sessionId; });
                return {
                    id: session.sessionId,
                    sender: {
                        name: session.userId,
                        avatar: "",
                        online: false,
                        username: session.userId,
                        lastMessageText: session.lastMessageText,
                        flowName: session.flowName
                    },
                    endDateTime: session.endDateTime,
                    unread: existingChat ? existingChat.unread : session.isRead === false,
                    messages: existingChat ? existingChat.messages : [],
                };
            });
            setChatList(chatsData);
            if (sessionIdParam && page == 1) {
                selectAndMarkAsRead(sessionIdParam);
            }
        }
    }, [sessions === null || sessions === void 0 ? void 0 : sessions.length]);
    // Fetch conversations when selectedSessionId changes
    useEffect(function () {
        if (selectedChat) {
            getConversationsBySessionId(selectedChat.id);
        }
    }, [selectedChat === null || selectedChat === void 0 ? void 0 : selectedChat.id]);
    // Update selectedChat when conversations for selectedSessionId change
    useEffect(function () {
        if (selectedChat) {
            var mappedMessages_1 = conversations.flatMap(function (item) {
                var messages = [];
                if (item.userMessage) {
                    messages.push({
                        id: "".concat(item.conversationItemId, "_user"),
                        sender: {
                            name: "User",
                            avatar: "",
                            online: false,
                            username: "user",
                        },
                        content: item.userMessage || "",
                        timestamp: new Date(item.createdDate).toLocaleString(),
                    });
                }
                if (item.botResponse) {
                    messages.push({
                        id: "".concat(item.conversationItemId, "_bot"),
                        sender: {
                            name: "AI Agent",
                            avatar: "https://doworkknowledgeprodapp.blob.core.windows.net/images/headsetMicRounded.svg",
                            online: false,
                            username: "aiagent",
                        },
                        content: item.botResponse || "",
                        timestamp: new Date(item.createdDate).toLocaleString(),
                    });
                }
                return messages;
            });
            setSelectedChat(function (prevChat) {
                return prevChat ? __assign(__assign({}, prevChat), { messages: mappedMessages_1 }) : prevChat;
            });
        }
    }, [conversations]);
    var selectAndMarkAsRead = function (sessionId) { return __awaiter(void 0, void 0, void 0, function () {
        var selectedChat, session;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    selectedChat = chatList.find(function (chat) { return chat.id === sessionId; });
                    if (!!selectedChat) return [3 /*break*/, 2];
                    return [4 /*yield*/, getSessionById(sessionId)];
                case 1:
                    session = _a.sent();
                    if (session) {
                        selectedChat = {
                            id: session.sessionId,
                            sender: {
                                name: session.userId,
                                avatar: "",
                                online: false,
                                username: session.userId,
                                lastMessageText: session.lastMessageText,
                                flowName: session.flowName
                            },
                            endDateTime: session.endDateTime,
                            unread: false,
                            messages: [],
                        };
                        setChatList(function (prevChatList) { return __spreadArray(__spreadArray([], prevChatList, true), [selectedChat], false); });
                        setSelectedChat(selectedChat);
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var handleSelectChat = function (chat) {
        var selectedChat = chatList.find(function (c) { return c.id === chat.id; });
        setSearchParams({ sessionId: chat.id });
        markSessionAsRead(chat.id);
        setSelectedChat(selectedChat);
        setChatList(function (prevChatList) {
            return prevChatList.map(function (c) { return (c.id === chat.id ? __assign(__assign({}, c), { unread: false }) : c); });
        });
    };
    return (_jsxs(Sheet, __assign({ sx: {
            flex: 1,
            width: "100%",
            mx: "auto",
            pt: { xs: "var(--Header-height)", md: 0 },
            display: "grid",
            gridTemplateColumns: {
                xs: "1fr",
                sm: "minmax(min-content, min(30%, 400px)) 1fr",
            },
        } }, { children: [_jsx(Sheet, __assign({ sx: {
                    position: { xs: "fixed", sm: "sticky" },
                    transform: {
                        xs: "translateX(calc(100% * (var(--MessagesPane-slideIn, 0) - 1)))",
                        sm: "none",
                    },
                    transition: "transform 0.4s, width 0.4s",
                    zIndex: 100,
                    width: "100%",
                    top: 52,
                } }, { children: sessionsIsLoading && !chatList.length ? (_jsx(ChatListSkeleton, {})) : (_jsx(ChatsPane, { chats: chatList, selectedChatId: (selectedChat === null || selectedChat === void 0 ? void 0 : selectedChat.id) || undefined, setSelectedChat: handleSelectChat, loadMoreChats: loadMoreChats, hasMoreChats: hasMoreChats || false })) })), conversationsIsLoading ? (_jsx(ChatSkeleton, {})) : selectedChat ? (_jsx(MessagesPane, { chat: selectedChat })) : (_jsx("div", __assign({ style: { textAlign: "center", marginTop: "20px" } }, { children: "Select a chat to view messages." })))] })));
};
