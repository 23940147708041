var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from "react";
import { Stack, FormControl, FormLabel, Grid, Select, Option, Input, Button, } from "@mui/joy";
import WebhookPostCallArgs from "../WebhookPostCallArgs";
function PostCallAction(props) {
    var action = props.action, actionIndex = props.actionIndex, onPostCallActionDelayInSecondsChange = props.onPostCallActionDelayInSecondsChange, onPostCallActionTypeChange = props.onPostCallActionTypeChange, onPostCallActionDelete = props.onPostCallActionDelete, onPostCallActionArgsPropertyChange = props.onPostCallActionArgsPropertyChange, onPostCallActionArgsNewHeader = props.onPostCallActionArgsNewHeader, onPostCallActionArgsUpdateHeader = props.onPostCallActionArgsUpdateHeader, onPostCallActionArgsDeleteHeader = props.onPostCallActionArgsDeleteHeader;
    return (_jsxs(Stack, { children: [_jsx(Grid, __assign({ container: true, columnSpacing: 2, rowSpacing: 1, alignItems: "center" }, { children: _jsx(Grid, __assign({ xs: 6 }, { children: _jsx(FormControl, { children: _jsxs(Select, __assign({ size: "sm", value: action.type, onChange: function (event, newValue) {
                                return onPostCallActionTypeChange(actionIndex, newValue);
                            } }, { children: [_jsx(Option, __assign({ value: "webhook" }, { children: "HTTP Request" })), _jsx(Option, __assign({ value: "email" }, { children: "Email To" }))] })) }) })) })), _jsx(Grid, __assign({ container: true, columnSpacing: 2, rowSpacing: 1, alignItems: "center" }, { children: _jsx(Grid, __assign({ xs: 6 }, { children: _jsxs(FormControl, __assign({ sx: { mt: 1 } }, { children: [_jsx(FormLabel, { children: "Post Call Execution Delay In Seconds" }), _jsx(Input, { size: "sm", type: "number", defaultValue: 0, value: action.postCallExecutionDelayInSeconds, onChange: function (event) {
                                    return onPostCallActionDelayInSecondsChange(actionIndex, Number.parseInt(event.target.value));
                                } })] })) })) })), action.type === "webhook" && action.argsObject && (_jsx(WebhookPostCallArgs, { args: action.argsObject, actionIndex: actionIndex, onPostCallActionArgsPropertyChange: onPostCallActionArgsPropertyChange, onPostCallActionDelete: onPostCallActionDelete, onPostCallActionArgsNewHeader: onPostCallActionArgsNewHeader, onPostCallActionArgsUpdateHeader: onPostCallActionArgsUpdateHeader, onPostCallActionArgsDeleteHeader: onPostCallActionArgsDeleteHeader })), action.type === "email" && (_jsxs(Stack, __assign({ sx: { my: 2, pl: { xs: 0 } }, spacing: 2 }, { children: [_jsx(FormLabel, { children: "Emails" }), _jsx(FormControl, { children: _jsx(Input, { size: "sm", value: action.argsObject.emailTo, placeholder: "email1@example.com, email2@example.com", onChange: function (event) {
                                return onPostCallActionArgsPropertyChange(actionIndex, "emailTo", event.target.value);
                            } }) }), _jsx(Button, __assign({ color: "danger", variant: "soft", sx: { alignSelf: "flex-end" }, onClick: function (c) { return onPostCallActionDelete(actionIndex); } }, { children: "Delete" }))] })))] }));
}
export default memo(PostCallAction);
