var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import ListItem from "@mui/joy/ListItem";
import Typography from "@mui/joy/Typography";
import AvatarWithStatus from "../AvatarWithStatus";
import { Box, ListDivider, ListItemButton, Stack, } from "@mui/joy";
import { toggleMessagesPane } from "../../../../utils";
import CircleIcon from "@mui/icons-material/Circle";
export default function ChatListItem(props) {
    var id = props.id, sender = props.sender, messages = props.messages, selectedChatId = props.selectedChatId, lastMessageText = props.lastMessageText, flowName = props.flowName, unread = props.unread, endDateTime = props.endDateTime, setSelectedChat = props.setSelectedChat;
    var selected = selectedChatId === id;
    // Convert to a Date object if endDateTime is a string
    var parseDate = function (date) {
        if (typeof date === "string") {
            var parsedDate = new Date(date);
            return isNaN(parsedDate.getTime()) ? null : parsedDate; // Return null if invalid date
        }
        return date instanceof Date ? date : null;
    };
    var calculateMinutesPassed = function (endDate) {
        if (!endDate)
            return null;
        var now = new Date();
        var diffInMilliseconds = now.getTime() - endDate.getTime();
        var diffInMinutes = Math.floor(diffInMilliseconds / 60000); // Convert milliseconds to minutes
        return diffInMinutes > 0 ? diffInMinutes : 0; // Return 0 if the time has already passed
    };
    var endDate = parseDate(endDateTime || "");
    var minutesPassed = calculateMinutesPassed(endDate);
    return (_jsxs(React.Fragment, { children: [_jsx(ListItem, { children: _jsxs(ListItemButton, __assign({ onClick: function () {
                        toggleMessagesPane();
                        setSelectedChat({ id: id, sender: sender, messages: messages });
                    }, selected: selected, color: "neutral", sx: { flexDirection: "column", alignItems: "initial", gap: 1 } }, { children: [_jsxs(Stack, __assign({ direction: "row", spacing: 1.5 }, { children: [_jsx(AvatarWithStatus, { online: sender.online, src: sender.avatar }), _jsx(Box, __assign({ sx: { flex: 1 } }, { children: _jsx(Typography, __assign({ level: "title-sm" }, { children: sender.name })) })), _jsxs(Box, __assign({ sx: { lineHeight: 1.5, textAlign: "right" } }, { children: [unread && _jsx(CircleIcon, { sx: { fontSize: 12 }, color: "primary" }), _jsx(Typography, __assign({ level: "body-xs", noWrap: true, sx: { display: { xs: "none", md: "block" } } }, { children: minutesPassed !== null
                                                ? minutesPassed < 60
                                                    ? "".concat(minutesPassed, " mins ago")
                                                    : minutesPassed < 1440 // 1440 minutes = 24 hours
                                                        ? "".concat(Math.floor(minutesPassed / 60), " hours ago")
                                                        : endDate === null || endDate === void 0 ? void 0 : endDate.toLocaleDateString() // Show date and time if more than 24 hours passed
                                                : "No Date" }))] }))] })), _jsx(Typography, __assign({ level: "body-sm", sx: {
                                display: "-webkit-box",
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            } }, { children: lastMessageText }))] })) }), _jsx(ListDivider, { sx: { margin: 0 } })] }));
}
