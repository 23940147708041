var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Radio, RadioGroup, FormControl, FormLabel, Stack, Tooltip, Switch, } from "@mui/joy";
import InfoOutlinedIcon from "@mui/icons-material/Info";
export default function ProactivePluginSelection(props) {
    var flow = props.flow, handleStartWithPluginChange = props.handleStartWithPluginChange, handleStartWithPluginEnabledChange = props.handleStartWithPluginEnabledChange;
    // Local states
    var _a = useState(false), isProactive = _a[0], setIsProactive = _a[1];
    var _b = useState(null), selectedPluginIndex = _b[0], setSelectedPluginIndex = _b[1];
    useEffect(function () {
        var hasProactivePlugin = flow.plugins.some(function (plugin) { return plugin.startWithFirstVariableQuestion; });
        setIsProactive(hasProactivePlugin);
        var activePluginIndex = flow.plugins.findIndex(function (plugin) { return plugin.startWithFirstVariableQuestion; });
        setSelectedPluginIndex(activePluginIndex !== -1 ? activePluginIndex : null);
    }, [flow.plugins]);
    var handleCheckboxChange = function (event) {
        var isChecked = event.target.checked;
        setIsProactive(isChecked);
        if (!isChecked) {
            setSelectedPluginIndex(null);
            handleStartWithPluginChange(null); // No event passed here
        }
        else {
            var existingPluginIndex = flow.plugins.findIndex(function (plugin) { return plugin.startWithFirstVariableQuestion; });
            var newPluginIndex = existingPluginIndex !== -1 ? existingPluginIndex : 0;
            setSelectedPluginIndex(newPluginIndex);
            handleStartWithPluginChange(newPluginIndex); // No event passed here
        }
    };
    var handleRadioChange = function (event) {
        var pluginIndex = parseInt(event.target.value, 10);
        setSelectedPluginIndex(pluginIndex);
        // Notify parent about the selected plugin
        handleStartWithPluginChange(pluginIndex);
    };
    return (_jsxs(Stack, __assign({ spacing: 2 }, { children: [_jsx(FormControl, { children: _jsxs(Stack, __assign({ direction: "row", alignItems: "center", spacing: 1 }, { children: [_jsx(FormLabel, { children: "Automatically ask the plugin\u2019s first question" }), _jsx(Tooltip, __assign({ title: "If enabled, the plugin will immediately ask its first question following the welcome message." }, { children: _jsx(InfoOutlinedIcon, { fontSize: "medium", color: "primary", sx: { ml: 0.2, mr: 1 } }) })), _jsx(Switch, { checked: isProactive, size: "sm", onChange: handleCheckboxChange })] })) }), isProactive && (_jsxs(FormControl, { children: [_jsx(FormLabel, { children: "Select a plugin to start with" }), _jsx(RadioGroup, __assign({ name: "proactivePlugin", value: (selectedPluginIndex === null || selectedPluginIndex === void 0 ? void 0 : selectedPluginIndex.toString()) || "", onChange: handleRadioChange }, { children: flow.plugins.map(function (plugin, index) { return (_jsx(Radio, { size: "sm", value: index.toString(), label: plugin.pluginName }, plugin.id)); }) }))] }))] })));
}
